<script>
import appConfig from "@/app.config";
import Stat from "@/shared/components/widgets/stat";
import DashboardCharts from "./dashboard-charts";
import ProfileCard from "./profile-card";
// Services
import AuthService from "@/api/services/auth.service.js";
import UserService from "@/api/services/user.service.js";

/**
 * Presonal Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Stat,
    ProfileCard,
    DashboardCharts
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: "Overview",
          active: true
        }
      ],
      /**** Data Variables ******/
      statData: [
        {
          icon: "bx bx-copy-alt",
          translationKey: "dashboard.statCard.totalDatafiles",
          value: 0
        },
        {
          icon: "bx bxs-user-rectangle",
          translationKey: "dashboard.statCard.totalDataRecords",
          value: 0
        },
        {
          icon: "bx bx-equalizer",
          translationKey: "dashboard.statCard.totalQualityScores",
          value: 0
        }
      ],
      loggedInUser: null,
      isPageLoading: false
    };
  },
  async mounted() {
    this.isPageLoading = true
    await this.getLoggedInUser();
    await this.getSummaryOfDatafiles();
    this.isPageLoading = false
  },
  methods: {
    async getLoggedInUser() {
      let getLogedInuserFromQuery = await AuthService.getLoggedInUser();
      if (getLogedInuserFromQuery) {
        this.loggedInUser = getLogedInuserFromQuery;
      }
    },
    async getSummaryOfDatafiles() {
      let summaryOfDatafiles = await UserService.getDatafilesSummary();
      this.statData[0].value = summaryOfDatafiles.numberOfDatafiles;
      this.statData[1].value = summaryOfDatafiles.numberOfDataRecords;
      this.statData[2].value = summaryOfDatafiles.numberOfQualityScores;
    },
    clickRedirectToUpload() {
      this.$router.push("./upload-dataset/step1");
    }
  }
};
</script>

<template>
  <div>
    <!-- Display Page Data -->
    <div v-if="!isPageLoading">
      <div class="row">
        <div class="col-xl-4">
          <!-- Profile Card -->
          <div class="row animate__animated animate__fadeInUp animate__slow">
            <div class="col-md-12">
              <ProfileCard />
            </div>
          </div>
        </div>
        <div class="col-xl-8">
          <div class="row animate__animated animate__fadeInUp animate__slow">
            <div v-for="stat of statData" :key="stat.icon" class="col-md-4">
              <Stat
                :icon="stat.icon"
                :translationKey="stat.translationKey"
                :value="stat.value"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- Number of Data files > 0 -->
        <div class="col-xl-12" v-if="this.statData[0].value > 0">
          <div
            class="card d-none d-md-block animate__animated animate__fadeInUp animate__slow"
          >
            <div class="card-body">
              <h4 class="card-title mb-4">
                {{ $t("dashboard.chartCard.title") }}
              </h4>
              <DashboardCharts />
            </div>
          </div>
        </div>
        <!-- Number of Datafiles === 0 -->
        <div
          class="col-md-12 animate__animated animate__fadeInUp animate__slow"
          v-else
        >
          <div class="card text-center">
            <div class="card-body p-5">
              {{ $t("dashboard.noDatafiles.title") }}
              <br />
              {{ $t("dashboard.noDatafiles.text") }}
              <br />
              <b-button
                variant="success"
                @click="clickRedirectToUpload()"
                class="mt-3"
              >
                <i class="bx bx-upload font-size-18 mr-2"></i
                >{{ $t("dashboard.noDatafiles.startNowButton") }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Display Page Loading -->
    <div class="row justify-content-center" v-else>
      <div class="col-6 text-center p-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>
    
  </div>
</template>
