import { ApiCall } from "../apiBase";

class QualityScoreInfoService {
  /********* get All Quality Score info ********/
  async getAllQualityScoreInfo() {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getQualityScoresInfo {
            getQualityScoresInfo {
              id
              name
              code
              creditPrice
              creditPriceForAdditionalDataPoint
            }
          }
        `,
        operationName: "getQualityScoresInfo"
      }
    });

    if (result?.data?.data?.getQualityScoresInfo && !result?.data?.errors) {
      return result?.data?.data?.getQualityScoresInfo;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }

  /******  get Dashboard Qulity Score Summary ******/
  async getDashboardQualityScoreSummary() {
    let data = null;

    await ApiCall({
      method: "post",
      data: {
        query: `
        query getDashboardQualityScoreSummary {
          getDashboardQualityScoreSummary {
            datafileNames
            red
            yellow
            green
            lightGreen
          }
        }
      `
      }
    }).then((result) => {
      if (
        !result.data.errors &&
        result.data.data.getDashboardQualityScoreSummary
      ) {
        data = result.data.data.getDashboardQualityScoreSummary;
      }
    });

    return data;
  }

  /****** get Number of Quality Scores ******/
  async getDashboardNumberOfQualityScores() {
    let data = null;

    await ApiCall({
      method: "post",
      data: {
        query: `
        query getDashboardNumberOfQualityScores {
          getDashboardNumberOfQualityScores {
            datafileNames
            scoreTypes {
              PS
              TS
              IBS
              CS
              OES
            }
          }
        }
      `
      }
    }).then((result) => {
      if (
        !result.data.errors &&
        result.data.data.getDashboardNumberOfQualityScores
      ) {
        data = result.data.data.getDashboardNumberOfQualityScores;
      }
    });

    return data;
  }
}

export default new QualityScoreInfoService();
