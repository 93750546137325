<script>
import { trafficLightQualityChartOptions } from "@/shared/components/chart-options";
import QualityScoreInfoService from "@/api/services/qualitySocreInfo.service.js";

export default {
  data() {
    return {
      trafficLightQualityChartOptions: null,
      trafficLightQualityChartData: null,
      showRScoresChart: false
    };
  },
  async mounted() {
    this.trafficLightQualityChartOptions = trafficLightQualityChartOptions;
    await this.setTrafficLightData();
  },
  methods: {
    async setTrafficLightData() {
      let trafficLightData = {};
      // fetch datafiles that the user has uploaded
      let res = await QualityScoreInfoService.getDashboardQualityScoreSummary();
      trafficLightData = {
        red: res.red,
        yellow: res.yellow,
        lightGreen: res.lightGreen,
        green: res.green,
        datafileNames: res.datafileNames
      };
      let xAxisLabels = trafficLightData.datafileNames;
      this.trafficLightQualityChartOptions.xaxis.categories = xAxisLabels;
      this.trafficLightQualityChartData = [
        {
          name: "0 - 40",
          data: trafficLightData.red
        },
        {
          name: "40 - 60",
          data: trafficLightData.yellow
        },
        {
          name: "60 - 80",
          data: trafficLightData.lightGreen
        },
        {
          name: "80 - 100",
          data: trafficLightData.green
        }
      ];

      this.showRScoresChart = true;
    }
  }
};
</script>
<template>
  <div>
    <div v-if="showRScoresChart">
      <apexchart
        class="apex-charts"
        type="bar"
        dir="ltr"
        height="362"
        :series="trafficLightQualityChartData"
        :options="trafficLightQualityChartOptions"
      ></apexchart>
    </div>
    <div class="row" v-else>
      <div class="col-md-12 text-center mt-3">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>
  </div>
</template>
